import { Box, Typography, Card, CardContent, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SupraTVHeader from '../components/SupraTVHeader';

import Grid from '@mui/material/Grid2';
import axios from 'axios';
import React from 'react';

const APIURL = process.env.REACT_APP_API_URL;

const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const hoursStr = hours > 0 ? `${hours} timer` : '';
    const minutesStr = minutes > 0 ? `${minutes} minutter` : '';
    const secondsStr = remainingSeconds > 0 ? `${remainingSeconds} sekunder` : '';
  
    return [hoursStr, minutesStr, secondsStr].filter(Boolean).join(' ') || '0 sekunder';
  };


function Users() {
    const token = sessionStorage.getItem("supratvtoken") || "";
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [users, setUsers] = useState<any[]>([]);

    function getsupratvusers() {
        axios.get(APIURL + "/supratv/users", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setUsers(response.data);
                }
                else {
                }
                setLoading(false);
            })
            .catch((e: Error) => {
                setLoading(false);
            });
    }

    const handleSelectTVSeries = (item: number) => {
        navigate("/series/" + item);
    };

    useEffect(() => {
        if (!sessionStorage.getItem('supratvtoken')) {
            navigate('/login');
        }
        getsupratvusers();
        setLoading(false);
    }, [navigate]);

    return (
        (token) ?
            <>
                <SupraTVHeader />
                <Grid container sx={{ ml: 2, mt: 2 }}>
                    <Grid>
                        <Typography variant="h6" component="h2">
                            Alle brukere
                        </Typography>

                        <TableContainer component={Paper} sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Bruker</strong></TableCell>
                                        <TableCell align="right"><strong>Episoder sett</strong></TableCell>
                                        <TableCell align="right"><strong>Tid brukt</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{user.username}</TableCell>
                                            <TableCell align="right">{user.watchedepisodes}</TableCell>
                                            <TableCell align="right">{formatTime(user.watchtime)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody
                                >
                            </Table>
                        </TableContainer>


                    </Grid>
                </Grid>

            </>
            :
            null
    );
}

export default Users;