import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PeopleIcon from '@mui/icons-material/People';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Link, OutlinedInput, Select } from '@mui/material';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
const APIURL = process.env.REACT_APP_API_URL;


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function SupraTVHeader() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("supratvtoken") || "";
  const isAdmin = sessionStorage.getItem("supratvadmin") || "0";
  const [loading, setLoading] = React.useState(false);
  const [searchOptions, setSearchOptions] = React.useState<any[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function getSearchOptions() {
    axios.get(APIURL + "/supratv/serieoptions", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
      .then((response: any) => {
        if (response.status === 200) {
          setSearchOptions(response.data);
        }
        setLoading(false);
      })
      .catch((e: Error) => {
        setLoading(false);
      });
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem> */}
    </Menu>
  );


  const [openNewSeries, setOpenNewSeries] = React.useState(false);
  const handleOpenNewSeries = () => setOpenNewSeries(true);
  const handleCloseNewSeries = () => setOpenNewSeries(false);

  React.useEffect(() => {
    getSearchOptions();
  }, []);

  return (
    (token) ?
      <Box sx={{ flexGrow: 1 }}>
        <Dialog
          open={openNewSeries}
          onClose={handleCloseNewSeries}
          PaperProps={{
            component: 'form',
            onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
              setLoading(true);
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData as any).entries());
              await axios.post(APIURL + "/supratv/series", formJson, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                  navigate("/series/" + response.data[0].tvsid);
                  setLoading(false);
                })
                .catch((e: any) => {
                  setLoading(false);
                });

              handleCloseNewSeries();
            },
          }}
        >
          <DialogTitle>Add new TV Series</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Add new TV Series
            </DialogContentText>
            <Grid size={12} mb={2}>

              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="tvsname">
                  Series name
                </InputLabel>
                <OutlinedInput
                  id="tvsname"
                  name="tvsname"
                  type="text"
                  label="Series Name"
                  required
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="tvsyear">
                  Year
                </InputLabel>
                <OutlinedInput
                  id="tvsyear"
                  name="tvsyear"
                  type="number"
                  label="Year"
                  required
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="tvslanguage">
                  Language
                </InputLabel>
                <Select
                  labelId="tvslanguage"
                  id="tvslanguage"
                  label="tvslanguage"
                  name="tvslanguage"
                  required
                >
                  <MenuItem value={"English"}>English</MenuItem>
                  <MenuItem value={"Norwegian"}>Norwegian</MenuItem>
                  <MenuItem value={"Swedish"}>Swedish</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="tvsdescriptionshort">
                  Description Short
                </InputLabel>
                <OutlinedInput
                  id="tvsdescriptionshort"
                  name="tvsdescriptionshort"
                  type="text"
                  label="Description Short"
                  multiline
                  rows={5}
                  required
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                <InputLabel htmlFor="tvsdescription">
                  Description
                </InputLabel>
                <OutlinedInput
                  id="tvsdescription"
                  name="tvsdescription"
                  type="text"
                  label="Description"
                  multiline
                  rows={5}
                  required
                />
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewSeries} variant="outlined" startIcon={<CloseIcon />}>Cancel</Button>
            <Button type="submit" variant="outlined" startIcon={<BorderColorIcon />}>Register</Button>
          </DialogActions>
        </Dialog>

        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' }, width: '100px' }}
            >
              <Link href="/" underline="none">SupraTV</Link>
            </Typography>
            <Autocomplete
              freeSolo
              options={searchOptions.map((option) => ({
                id: option.tvsid,
                label: option.tvsname
              }))}
              renderInput={(params) => (
                <Search sx={{ width: '100%' }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    {...params.InputProps}
                    inputProps={{
                      ...params.inputProps,
                      'aria-label': 'søk',
                    }}
                    placeholder="Søk..."
                    fullWidth
                  />
                </Search>
              )}
              onChange={(event, value: any) => {
                navigate("/series/" + value?.id);
              }}
              sx={{ width: '95%' }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
              {/* <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
              <IconButton
                size="large"
                edge="end"
                aria-label="Brukere"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => navigate("/profile")}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="Brukere"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => navigate("/users")}
                color="inherit"
              >
                <PeopleIcon />
              </IconButton>
              {(isAdmin === "1") &&
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="Add new series"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleOpenNewSeries}
                  color="inherit"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              }
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      :
      null
  );
}