import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { CssBaseline, ThemeProvider } from '@mui/material';
import FrontPage from './pages/FrontPage';
import { SupraTVTheme } from './components/SupraTVTheme';
import TVSerie from './pages/TVSerie';
import ViewVideo from './pages/ViewVideo';
import All from './pages/All';
import Users from './pages/Users';
import Profile from './pages/Profile';
const App: React.FC = () => {
  return (
    <Router>
      <ThemeProvider theme={SupraTVTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/all" element={<All />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/series/:serieid" element={<TVSerie />} />
          <Route path="/v/:seriehash" element={<ViewVideo />} />
          <Route path="/users" element={<Users />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;