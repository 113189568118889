import { Box, Typography, Card, CardContent, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Dialog, DialogContent, DialogContentText, FormControl, InputLabel, OutlinedInput, DialogActions } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SupraTVHeader from '../components/SupraTVHeader';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';
import axios from 'axios';
import React from 'react';

const APIURL = process.env.REACT_APP_API_URL;

function Profile() {
    const token = sessionStorage.getItem("supratvtoken") || "";
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const handleCloseChangePasswordModal = () => {
        setOpenChangePasswordModal(false);
    };

    const handleOpenChangePasswordModal = () => {
        setOpenChangePasswordModal(true);
    };


    useEffect(() => {
        if (!sessionStorage.getItem('supratvtoken')) {
            navigate('/login');
        }
        setLoading(false);
    }, [navigate]);

    return (
        (token) ?
            <>
                <SupraTVHeader />
                <Dialog
                    open={openChangePasswordModal}
                    onClose={handleCloseChangePasswordModal}
                    PaperProps={{
                        component: 'form',
                        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                            setLoading(true);
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries((formData as any).entries());
                            await axios.post(APIURL + "/supratv/changepassword", formJson, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                                .then((response: any) => {
                                    setLoading(false);
                                })
                                .catch((e: any) => {
                                    setLoading(false);
                                });
                            handleCloseChangePasswordModal();
                        },
                    }}
                >
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}>
                            Bytt passord
                        </DialogContentText>
                        <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                            <InputLabel htmlFor="password">
                                Nytt passord
                            </InputLabel>
                            <OutlinedInput
                                id="password"
                                name="password"
                                type="password"
                                label="Nytt passord"
                                required
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseChangePasswordModal} variant="outlined" startIcon={<CloseIcon />}>Cancel</Button>
                        <Button type="submit" variant="outlined" startIcon={<EditIcon />}>Add</Button>
                    </DialogActions>
                </Dialog>

                <Grid container sx={{ ml: 2, mt: 2 }}>
                    <Grid>
                        <Typography variant="h6" component="h2">
                            Min profil
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleOpenChangePasswordModal}
                                disabled={loading}
                            >
                                Endre passord
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </>
            :
            null
    );
}

export default Profile;