import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
const APIURL = process.env.REACT_APP_API_URL;
const SUPRATVURL = process.env.REACT_APP_SUPRATV_URL;
const TVDBURL = process.env.REACT_APP_TVDB_URL;

interface SupraTVFrontImageProps {
  tveid: string;
  src: string;
  alt: string;
  fallback: string;
  overlayText?: string;
  watched?: boolean;
  resolution?: string;
  onEpisodeDeleted?: (tveid: string) => void;
}

const SupraTVFrontImage: React.FC<SupraTVFrontImageProps> = ({
  tveid,
  src,
  alt,
  fallback,
  overlayText,
  watched,
  resolution,
  onEpisodeDeleted,
}) => {
  const [bgImage, setBgImage] = useState(src);
  const [isWatched, setIsWatched] = useState(watched);
  const token = sessionStorage.getItem("supratvtoken") || "";
  const isAdmin = sessionStorage.getItem("supratvadmin") || "0";

  const handleWatchedToggle = () => {
    setIsWatched((prev) => !prev);
    axios
      .post(
        APIURL + "/supratv/watched/" + tveid,
        { watched: isWatched },
        {
          withCredentials: true,
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response: any) => {
        console.log(response);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const handleDeleteEpisode = () => {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("tveid", tveid);
  
    axios
      .post(
        TVDBURL + "/supratv-deleteepisode.php",
        formData,
        {
          withCredentials: false,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response: any) => {
        if (onEpisodeDeleted) {
          onEpisodeDeleted(tveid);
        }
      })
      .catch((e: any) => {
        console.error("Error deleting episode:", e);
      });
  };

  const getResolutionLogo = () => {
    if (resolution) {
      const numericResolution = parseInt(resolution, 10);

      if (numericResolution >= 8000) {
        return '8k';
      } else if (numericResolution >= 2160) {
        return '4k';
      } else if (numericResolution >= 1080) {
        return '1080p';
      } else if (numericResolution >= 720) {
        return '720p';
      } else if (numericResolution > 1) {
        return '480p';
      }
    }
    return null; // Ensures a fallback value when resolution is not valid or not provided
  };

  const resolutionLogo = getResolutionLogo();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {
    handleDeleteEpisode();
    setOpenDeleteDialog(false);
  };

  return (
    <div
      style={{
        width: '250px',
        height: '350px',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '20px',
        fontWeight: 'bold',
        textShadow: '1px 1px 5px rgba(0, 0, 0, 0.7)',
        position: 'relative',
      }}
      role="img"
      aria-label={alt}
      onError={() => setBgImage(fallback)}
    >
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Bekreft sletting</DialogTitle>
        <DialogContent>
          Er du sikker på at du vil slette denne episoden?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Avbryt</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Slett
          </Button>
        </DialogActions>
      </Dialog>

      {/* Resolution Logo */}
      {resolutionLogo && (
        <div
          style={{
            width: '40px',
            height: '40px',
            position: 'absolute',
            top: '0px',
            right: '0px',
            backgroundImage: `url(${SUPRATVURL}/images/icons/${resolutionLogo}.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            color: 'white',
            padding: '0px',
            borderRadius: '5px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
        </div>
      )}

      {overlayText && (
        <div
          style={{
            position: 'absolute',
            bottom: '0px',
            left: '10px',
            right: '10px',
            padding: '5px',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            borderRadius: '5px 5px 0px 0px',
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ textAlign: 'left' }}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleWatchedToggle();
              }}
              sx={{ color: isWatched ? 'green' : 'red' }}
            >
              {isWatched ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            {overlayText}</span>
          {isAdmin &&
            <IconButton
              size="small"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(e);
              }}
            >
              <DeleteIcon />
            </IconButton>
          }
        </div>
      )}
    </div>
  );
};

export default SupraTVFrontImage;